import jquery from 'jquery';
import 'what-input';
import '@splidejs/splide/dist/js/splide.min';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/tooltip';
import 'bootstrap-datepicker';
import '@assets/utils/util';
import '@assets/components/spinner';

window.jQuery = window.$ = jquery;
